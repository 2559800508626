/* eslint-disable no-unused-vars */
import axios from "@/plugins/axios";

const actions = {
  async set_user({ commit }) {
    try {
      const { data } = await axios.get("get_users/");
      commit("SET_USER", data);
      return data;
    } catch (error) {
      return null;
    }
  },

  async get_user({ commit }, pk) {
    try {
      const { data } = await axios.get("get_user/", {
        params: {
          user: pk,
        },
      });
      return data;
    } catch (error) {
      return null;
    }
  },

  async add_user({ commit, dispatch }, params) {
    try {
      const { email, username, password } = params;
      const { data } = await axios.post("auth/users/", {
        email,
        username,
        password,
      });

      const { group } = params;
      const user_group = {
        group,
        user: data.id,
      };

      await dispatch("add_user_group", user_group);

      let data_full = [];
      await dispatch("get_user", data.id).then((res) => {
        data_full = res;
      });

      commit("ADD_USER", data_full);
      return data;
    } catch (error) {
      return null;
    }
  },

  async delete_user({ commit }, params) {
    try {
      //
      const { user } = params;
      const { data } = await axios.post(`remove_user/`, {
        user: user,
      });

      commit("DELETE_USER", data.id);
      return data;
    } catch (error) {
      return null;
    }
  },

  //
  async set_group({ commit }) {
    try {
      const { data } = await axios.get("group");
      commit("SET_GROUP", data);
      return data;
    } catch (error) {
      return null;
    }
  },

  async add_user_group({ commit }, params) {
    try {
      const { group, user } = params;
      const { data } = await axios.post("group/", { group, user });
      return data;
    } catch (error) {
      return null;
    }
  },
};
export default actions;
