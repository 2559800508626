const getters = {
  get_modalidad: (state) => {
    return state.modalidad_data;
  },

  get_modalidad_select: (state) => {
    return state.modalidad_data.map((item) => {
      return { id: item.id, nombre: item.nombre };
    });
  },
};

export default getters;
