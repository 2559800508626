import axios from "@//plugins//axios.js";

const actions = {
  async set_auth({ dispatch }, params) {
    try {
      const { data } = await axios.post("auth/token/login", params);
      let { auth_token } = data;
      localStorage.setItem("token-oca-unap", JSON.stringify(auth_token));
      await dispatch("set_user");
      return data;
    } catch (error) {
      return null;
    }
  },

  async set_user({ commit }) {
    try {
      const { data } = await axios.get("get_user/");
      if (Object.entries(data).length > 0) commit("SET_AUTH", data);
      return data;
    } catch (error) {
      return null;
    }
  },

  clear_auth({ commit }) {
    commit("CLEAR_AUTH");
  },
};
export default actions;
