/* eslint-disable no-unused-vars */
import axios from "@//plugins//axios.js";

const actions = {
  async set_postulante({ commit }, params) {
    try {
      let {
        dni,
        limit,
        modalidad,
        escuela,
        sede,
        ingresantes,
        universidad_procedencia,
      } = params;

      dni = dni ? dni : "";
      ingresantes = ingresantes || false;

      const { data } = await axios.get("get_postulante_full_v2", {
        params: {
          dni,
          limit,
          modalidad,
          escuela,
          sede,
          ingresantes,
          universidad_procedencia,
        },
      });
      commit("SET_POSTULANTE", data);
      return data;
    } catch (error) {
      commit("SET_POSTULANTE", []);
      return null;
    }
  },

  async get_postulante_full_v2_for_validation({ commit }, { dni, id }) {
    try {
      const { data } = await axios.get(
        "get_postulante_full_v2_for_validation",
        {
          params: {
            id,
            dni,
          },
        }
      );
      return data.filter((item) => !item.validado);
    } catch (error) {
      commit("SET_POSTULANTE", []);
      return null;
    }
  },

  async set_postulante_by_id({ commit }, params) {
    try {
      const { id, conFoto } = params;
      const { data } = await axios.get("get_declaracion_jurada_by_id/", {
        params: { id, conFoto },
      });

      return data;
    } catch (error) {
      return null;
    }
  },

  async set_postulante_by_dni({ commit }, dni) {
    try {
      const { data } = await axios.get("get_declaracion_jurada_by_dni/", {
        params: { dni },
      });

      return data;
    } catch (error) {
      return null;
    }
  },

  async get_links_fotos({ commit }) {
    try {
      const { data } = await axios.get("get_images_on_zip");
      return data;
    } catch (error) {
      return null;
    }
  },

  async marcar_ingreso({ commit }) {
    try {
      const { data } = await axios.get("get_images_on_zip");
      return data;
    } catch (error) {
      return null;
    }
  },

  async modificarPostulante({ commit }, params) {
    try {
      const { status } = await axios.post("modificarPostulante/", params);
      return status;
    } catch (error) {
      return null;
    }
  },

  validar_localmente({ commit }, value) {
    commit("VALIDAR_LOCALMENTE", value);
  },
  //
  async getInfoPostulante({ commit }, id) {
    try {
      const { data } = await axios.get("getInfoPostulante", {
        params: {
          id,
        },
      });
      return data;
    } catch (error) {
      return null;
    }
  },

  async getCaracteristicasGenerales({ commit }, id) {
    try {
      const { data } = await axios.get("getCaracteristicasGenerales", {
        params: {
          id,
        },
      });
      return data;
    } catch (error) {
      return null;
    }
  },

  async getCaracteristicasEducativas({ commit }, id) {
    try {
      const { data } = await axios.get("getCaracteristicasEducativas", {
        params: {
          id,
        },
      });
      return data;
    } catch (error) {
      return null;
    }
  },

  async getPrograma({ commit }, id) {
    try {
      const { data } = await axios.get("getPrograma", {
        params: {
          id,
        },
      });
      return data;
    } catch (error) {
      return null;
    }
  },

  async save_postulante_requisito({ commit }, { postulante_id, requisito }) {
    try {
      const { data } = await axios.post(
        "save_postulante_requisitos/",
        {
          postulante_id,
          requisitos: [requisito]
        }
      );



      return data || [];
    } catch (error) {
      return null;
    }
  },
};
export default actions;
