const mutations = {
  SET_NOTA(state, data) {
    state.nota_data = data;
  },

  ADD_NOTA(state, data) {
    data.forEach((element) => {
      state.nota_data.push(element);
    });
  },

  UPDATE_NOTA(state, data) {
    data.forEach((element) => {
      const index = state.nota_data.findIndex((item) => item.id == element.id);

      if (index > -1) {
        state.nota_data[index] = element;
      }
    });
  },

  DELETE_NOTA(state, pk) {
    state.nota_data = state.nota_data.filter((item) => item.id != pk);
  },

  //
};
export default mutations;
