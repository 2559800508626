const getters = {
    get_screen: (state) => {
        return state.screen;
    },

    get_screen_text: (state) => {
        return state.screen_text;
    },

    get_domain: (state) => {
        return state.domain;
    },
};
export default getters;
