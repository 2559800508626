const getters = {
  get_user: (state) => {
    return state.user_data;
  },

  get_group: (state) => {
    return state.group_data;
  },
};
export default getters;
