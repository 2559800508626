const mutations = {
  SET_SCREEN(state, data) {
    state.screen = data;
  },

  SET_SCREEN_TEXT(state, data) {
    state.screen_text = data;
  },
};
export default mutations;
