const actions = {
  async set_screen({ commit }, value) {
    commit("SET_SCREEN", value);
  },

  set_screen_text({ commit }, value) {
    commit("SET_SCREEN_TEXT", value);
  },
};
export default actions;
