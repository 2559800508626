const getters = {
  get_pago: (state) => {
    return state.pago_data;
  },

  get_pago_by_id: (state) => (id) => {
    return state.pago_data.find((item) => item.id == id);
  },
};
export default getters;
