import Vue from "vue";
import Vuex from "vuex";
//
import auth from "./auth";
import tools from "./tools";
import menu from "./menu";
import user from "./user";
import postulante from "./postulante";
import nota from "./nota";
import pago from "./pago";
import modalidad from "./modalidad";
import escuela from "./escuela";
import sede from "./sede";
//
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    tools,
    menu,
    user,
    postulante,
    nota,
    pago,
    modalidad,
    escuela,
    sede,
  },
});
