<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  created() {
    window.addEventListener("resize", this.windowsResize);
  },

  async mounted() {
    this.windowsResize();
    this.$store.dispatch("auth/set_user");
    this.setCurrentDomain();
  },

  destroyed() {
    window.removeEventListener("resize", this.windowsResize);
  },


  methods: {
    windowsResize() {
      const width = window.innerWidth;
      if ((width > 0 || width >= 640) && width < 768)
        this.$store.dispatch("tools/set_screen_text", "sm");
      else if (width >= 768 && width < 1024)
        this.$store.dispatch("tools/set_screen_text", "md");
      else if (width >= 1024 && width < 1280)
        this.$store.dispatch("tools/set_screen_text", "lg");
      else if (width >= 1280 && width < 1536)
        this.$store.dispatch("tools/set_screen_text", "xl");
      else this.$store.dispatch("tools/set_screen_text", "2xl");

      this.$store.dispatch("tools/set_screen", width);
      //
    },

    setCurrentDomain() {
      const domain = JSON.parse(localStorage.getItem("other-domain"));
      if (domain) {
        this.$store.commit("tools/SET_DOMAIN", domain);
      }
    },
  },
};
</script>
