import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

//
import Vuesax from "vuesax";
import "vuesax/dist/vuesax.css";
Vue.use(Vuesax, {
  colors: {
    success: "#34D3A5",
  },
});
//

import vSelect from "vue-select";
Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";
//

import "./plugins/parseMoney";
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
